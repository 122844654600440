import client from "../libs/HttpClient";
class ContactService {
  static sendContactQuery(request) {
    return client.post("contact/send-contact-query", request, {});
  }
  static getCountryCodeList(request) {
    return client.get("common/country-code-list", request);
  }
}
export { ContactService };
