import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar, Nav, Container, Button, Offcanvas } from "react-bootstrap";
import header_logo from "assets/images/header_logo.png";
import {
  faCircleUser,
  faLocationDot,
  faPenToSquare,
  faBars,
  faSearch,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { RightSidebar } from "../RightSidebar/RightSidebar";
import { Sidebar } from "../Sidebar/Sidebar";
import { Link } from "react-router-dom";
import us_location from "assets/images/Simplification.svg";
import filter_icon from "assets/images/filter_icon.svg";
import { Formik, Form, Field } from "formik";

const Header = ({
  onToggleSidebar,
  hideHeaderAndSidebars,
  getSettingData,
  t,
}) => {
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);

  const toggleLeftSidebar = () => {
    handleNavbarToggle();
    setShowLeftSidebar(!showLeftSidebar);
    if (!showLeftSidebar) setShowRightSidebar(false);
    onToggleSidebar(!showLeftSidebar, false);
  };

  const toggleRightSidebar = () => {
    handleNavbarToggle();
    setShowRightSidebar(!showRightSidebar);
    if (!showRightSidebar) setShowLeftSidebar(false);
    onToggleSidebar(false, !showRightSidebar);
  };
  const [currentView, setCurrentView] = useState("desktop");
  const [isMobile, setIsMobile] = useState(false);
  const [tabView, setTabView] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const handleNavbarToggle = () => {
    console.log("first");
    setIsNavbarOpen(!isNavbarOpen);
    if (!isNavbarOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };
  const handleCollapse = () => {
    setIsNavbarOpen(false);
    document.body.classList.remove("no-scroll");
  };

  useEffect(() => {
    // Function to handle media query changes
    const handleMediaChange = () => {
      const isMobile = window.matchMedia("(max-width: 575px)").matches;
      const isTablet = window.matchMedia(
        "(min-width: 576px) and (max-width: 991px)"
      ).matches;

      if (isMobile) {
        setIsMobile(true);
        setCurrentView("phone");
        setTabView(false);
      } else if (isTablet) {
        setIsMobile(false);
        setCurrentView("tablet");
        setTabView(true);
      } else {
        setIsMobile(false);
        setCurrentView("desktop");
        setTabView(false);
      }
    };

    // Initial check
    handleMediaChange();

    // Add event listeners for media queries
    window.addEventListener("resize", handleMediaChange);

    // Cleanup event listeners on component unmount
    return () => window.removeEventListener("resize", handleMediaChange);
  }, []);

  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        className="navbar-border sticky-header"
      >
        <Container fluid className="header-container-main">
          {currentView === "desktop" && (
            <>
              {!hideHeaderAndSidebars ? (
                <>
                  <Button
                    onClick={toggleLeftSidebar}
                    className="me-2 d-lg-none left-toggle bg-transparent border-0"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Button>
                  <div className="header-content">
                    <Nav.Link>
                      <Button
                        variant="outline-light"
                        className="d-flex align-items-center me-3 btn-add"
                      >
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          className="me-2"
                        />
                        Alabama
                      </Button>
                    </Nav.Link>

                    <Nav className="ms-auto responsive-nav">
                      <Nav.Link>
                        <Button
                          variant="outline-light"
                          className="me-2 btn-add"
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="me-2"
                          />
                          Post
                        </Button>
                      </Nav.Link>
                      <Nav.Link>
                        <Button variant="outline-light btn-add">
                          <FontAwesomeIcon
                            icon={faCircleUser}
                            className="me-2"
                          />
                          <Link
                            to={"/register"}
                            className="text-decoration-none btn-link"
                          >
                            Login/Signup
                          </Link>
                        </Button>
                      </Nav.Link>
                    </Nav>
                  </div>
                  <Button
                    // variant="primary"
                    onClick={toggleRightSidebar}
                    className="ms-md-2 d-lg-none right-toggle bg-transparent border-0"
                  >
                    <img src={us_location} height={30} width={30} alt="" />
                    {/* <FontAwesomeIcon icon={faBars} /> */}
                  </Button>
                </>
              ) : (
                <>
                  <div>
                    <Link
                      to="/"
                      className="text-white text-decoration-none ms-3"
                    >
                      <img
                        src={
                          getSettingData?.data
                            .home_page_general_home_page_logo || header_logo
                        }
                        alt="Header logo"
                        className="home-logo"
                      />
                    </Link>
                  </div>
                </>
              )}
            </>
          )}
          {currentView === "phone" && (
            <>
              {!hideHeaderAndSidebars ? (
                <>
                  <div className="header-mobile">
                    <div>
                      <Link
                        to="/"
                        className="text-white text-decoration-none ms-3"
                      >
                        <img
                          src={
                            getSettingData?.data
                              .home_page_general_home_page_logo || header_logo
                          }
                          alt="Header logo"
                          className="home-logo"
                        />
                      </Link>
                    </div>
                    <div>
                      <Button
                        onClick={toggleLeftSidebar}
                        className="me-2 d-lg-none left-toggle bg-transparent border-0"
                      >
                        <FontAwesomeIcon icon={faBars} />
                      </Button>
                    </div>
                  </div>
                  <div className="header-mobile-buttons my-3">
                    <div>
                      <Nav.Link>
                        <Button
                          variant="outline-light"
                          className="d-flex align-items-center me-3 btn-add"
                        >
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="me-2"
                          />
                          Alabama
                        </Button>
                      </Nav.Link>
                    </div>
                    <div>
                      <Nav.Link>
                        <Button
                          variant="outline-light"
                          className="me-2 btn-add"
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="me-2"
                          />
                          Post
                        </Button>
                      </Nav.Link>
                    </div>
                    <div>
                      <Nav.Link>
                        <Button variant="outline-light btn-add">
                          <FontAwesomeIcon
                            icon={faCircleUser}
                            className="me-2"
                          />
                          <Link
                            to={"/register"}
                            className="text-decoration-none btn-link"
                          >
                            Login/Signup
                          </Link>
                        </Button>
                      </Nav.Link>
                    </div>
                  </div>
                  <div className="header-mobile">
                    <div className="search-mobile">
                      <Formik
                        initialValues={{ search: "" }} // Initial form state
                        onSubmit={(values) => {
                          console.log("Search term:", values.search); // Action on form submission
                        }}
                      >
                        {({ values, handleChange, handleSubmit }) => (
                          <Form onSubmit={handleSubmit} className="d-flex">
                            <div className="input-group mobile-search">
                              <Field
                                type="text"
                                id="search"
                                name="search"
                                placeholder="     Search Collegely"
                                className="form-control text-light"
                                onChange={handleChange}
                                style={{
                                  color: "white",
                                  "::placeholder": {
                                    color: "white",
                                  },
                                }}
                              />
                              {values.search === "" && (
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  className="search-icon position-absolute"
                                />
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    <div>
                      <Button
                        // variant="primary"
                        onClick={toggleRightSidebar}
                        className="ms-md-2 d-lg-none right-toggle bg-transparent border-0"
                      >
                        <img src={filter_icon} alt="filter_icon" />
                        {/* <FontAwesomeIcon icon={faBars} /> */}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="header-mobile">
                    <div>
                      <Link
                        to="/"
                        className="text-white text-decoration-none ms-3"
                      >
                        <img
                          src={
                            getSettingData?.data
                              .home_page_general_home_page_logo || header_logo
                          }
                          alt="Header logo"
                          className="home-logo"
                        />
                      </Link>
                    </div>
                    <div>
                      <Button
                        onClick={toggleLeftSidebar}
                        className="me-2 d-lg-none left-toggle bg-transparent border-0"
                      >
                        <FontAwesomeIcon icon={faBars} />
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {currentView === "tablet" && (
            <>
              {!hideHeaderAndSidebars ? (
                <>
                  <div className="header-mobile">
                    <div>
                      <Link
                        to="/"
                        className="text-white text-decoration-none ms-3"
                      >
                        <img
                          src={
                            getSettingData?.data
                              .home_page_general_home_page_logo || header_logo
                          }
                          alt="Header logo"
                          className="home-logo"
                        />
                      </Link>
                    </div>
                    <div>
                      <Button
                        onClick={toggleLeftSidebar}
                        className="me-2 d-lg-none left-toggle bg-transparent border-0"
                      >
                        <FontAwesomeIcon icon={faBars} />
                      </Button>
                    </div>
                  </div>
                  <div className="header-mobile-buttons my-3">
                    <div>
                      <Nav.Link>
                        <Button
                          variant="outline-light"
                          className="d-flex align-items-center me-3 btn-add"
                        >
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="me-2"
                          />
                          Alabama
                        </Button>
                      </Nav.Link>
                    </div>
                    <div>
                      <Nav.Link>
                        <Button
                          variant="outline-light"
                          className="me-2 btn-add"
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="me-2"
                          />
                          Post
                        </Button>
                      </Nav.Link>
                    </div>
                    <div>
                      <Nav.Link>
                        <Button variant="outline-light btn-add">
                          <FontAwesomeIcon
                            icon={faCircleUser}
                            className="me-2"
                          />
                          <Link
                            to={"/register"}
                            className="text-decoration-none btn-link"
                          >
                            Login/Signup
                          </Link>
                        </Button>
                      </Nav.Link>
                    </div>
                    <div>
                      <Formik
                        initialValues={{ search: "" }} // Initial form state
                        onSubmit={(values) => {
                          console.log("Search term:", values.search); // Action on form submission
                        }}
                      >
                        {({ values, handleChange, handleSubmit }) => (
                          <Form onSubmit={handleSubmit} className="d-flex">
                            <div className="input-group mobile-search">
                              <Field
                                type="text"
                                id="search"
                                name="search"
                                placeholder="     Search Collegely"
                                className="form-control text-light"
                                onChange={handleChange}
                                style={{
                                  color: "white",
                                  "::placeholder": {
                                    color: "white",
                                  },
                                }}
                              />
                              {values.search === "" && (
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  className="search-icon position-absolute"
                                />
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    <div>
                      <Button
                        // variant="primary"
                        onClick={toggleRightSidebar}
                        className="ms-md-2 d-lg-none right-toggle bg-transparent border-0"
                      >
                        <img src={filter_icon} alt="filter_icon" />
                        {/* <FontAwesomeIcon icon={faBars} /> */}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="header-mobile">
                    <div>
                      <Link
                        to="/"
                        className="text-white text-decoration-none ms-3"
                      >
                        <img
                          src={
                            getSettingData?.data
                              .home_page_general_home_page_logo || header_logo
                          }
                          alt="Header logo"
                          className="home-logo"
                        />
                      </Link>
                    </div>
                    <div>
                      <Button
                        onClick={toggleLeftSidebar}
                        className="me-2 d-lg-none left-toggle bg-transparent border-0"
                      >
                        <FontAwesomeIcon icon={faBars} />
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Container>
      </Navbar>

      {/* Collapsible Left Sidebar */}
      <Offcanvas
        show={showLeftSidebar}
        onHide={toggleLeftSidebar}
        placement="start"
        scroll
        backdrop="static"
        className="offcanvas-left"
      >
        {/* <Offcanvas.Header closeButton></Offcanvas.Header> */}
        <Sidebar />
      </Offcanvas>

      {/* Collapsible Right Sidebar */}
      <Offcanvas
        show={showRightSidebar}
        onHide={toggleRightSidebar}
        placement="end"
        scroll
        backdrop="static"
        className="offcanvas-right"
      >
        <RightSidebar />
      </Offcanvas>
    </>
  );
};

export default Header;
