import Widget from "common/components/Widget";
import React from "react";
import { Row, Col } from "react-bootstrap";

const Dashboard = () => {
  return (
    <div className="dashboard p-3">
      <h1>Dashboard</h1>
    </div>
  );
};

export default Dashboard;
