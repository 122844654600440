import React from "react";
import ErrorPage from "assets/images/404-page.png";
import PropTypes from "prop-types";
import { Button, Container, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "assets/scss/page/_notfound.scss";
import header_logo from "assets/images/header_logo.png";
const PageNotFound = ({ t, getSettingData }) => {
  const location = useLocation();
  const message = location.state?.message || "Page not found";
  console.log("message: ", message);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            <img
              src={
                getSettingData?.data.home_page_general_home_page_logo ||
                header_logo
              }
              alt="dashboard_logo"
              className="nav-brand"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <div className="page-not-found">
          <img src={ErrorPage} alt="404" className="not-found-image" />
          <h1 className="page-heading-center">Oops something wrong</h1>
          <div className="error-page-text mt-2">{message}</div>
          <div className="primary-button my-3">
            <Button className="p-2" onClick={handleClick} loading={false}>
              Back to Home
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

PageNotFound.propTypes = {
  from: PropTypes.string,
  t: PropTypes.func,
};

export { PageNotFound };
