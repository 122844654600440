import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { ContactService } from "api";
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetCountryCodeList = (onSuccess, onError = onDefaultError) => {
  return useQuery("country-code-list", ContactService.getCountryCodeList, {
    onSuccess,
    onError,
  });
};
const useSendContactQuery = (onSuccess, onError = onDefaultError) => {
  return useMutation(ContactService.sendContactQuery, {
    onSuccess,
    onError,
  });
};

export { useSendContactQuery, useGetCountryCodeList };
