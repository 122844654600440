import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "assets/scss/page/_auth.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NavItemsCommon } from "common/components";
import { EditEmailNavItems } from "config/navConfig";
import { useChangePassword } from "hooks";
import validationSchema from "./ChangePasswordValidation";
const initialValues = {
  old_password: "",
  new_password: "",
};

const ChangePassword = ({ t }) => {
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle password visibility
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const { mutate: doChangePassword, isLoading } = useChangePassword((res) => {
    if (res.data) {
      toast.success(res.message);
    }
  });

  return (
    <div>
      <h2>My Profile</h2>
      <NavItemsCommon navItemName={EditEmailNavItems} t={t} />
      <div className="d-flex justify-content-center">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            doChangePassword(values);
          }}
        >
          {({ errors, touched }) => (
            <FormikForm className="login-form change-pass-form ">
              <Form.Group className="mb-3">
                <Form.Label>Old Password</Form.Label>
                <InputGroup>
                  <Field
                    as={FormControl}
                    type={showOldPassword ? "text" : "password"}
                    name="old_password"
                    placeholder="Enter old password"
                    className="login-password w-100"
                    autoComplete="off"
                    isInvalid={!!errors.old_password && touched.old_password}
                  />
                  <span className="password-icon">
                    <InputGroup.Text
                      onClick={toggleOldPasswordVisibility}
                      style={{ cursor: "pointer" }}
                    >
                      {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroup.Text>
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors.old_password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <InputGroup>
                  <Field
                    as={FormControl}
                    type={showPassword ? "text" : "password"}
                    name="new_password"
                    placeholder="Enter new password"
                    className="login-password w-100"
                    autoComplete="off"
                    isInvalid={!!errors.new_password && touched.new_password}
                  />
                  <span className="password-icon">
                    <InputGroup.Text
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroup.Text>
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors.new_password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Field
                    as={FormControl}
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm new password"
                    className="login-password"
                    autoComplete="off"
                    isInvalid={
                      !!errors.confirmPassword && touched.confirmPassword
                    }
                  />
                  <span className="password-icon">
                    <InputGroup.Text
                      onClick={togglePasswordVisibilityConfirm}
                      style={{ cursor: "pointer" }}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroup.Text>
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Button
                type="submit"
                variant="primary"
                className="w-100 my-3 change-pass-btn"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Loading...
                  </>
                ) : (
                  "Confirm"
                )}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
