import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import validationSchema from "./RegistrationValidation";
import "assets/scss/page/_auth.scss";
import header_logo from "assets/images/header_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useRegisterUser } from "hooks";
import { setToken } from "store/features/authSlice";

const initialValues = {
  email: "",
  password: "",
  name: "",
  confirmPassword: "",
};

const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const { mutate: doRegistration, isLoading } = useRegisterUser((res) => {
    if (res.data) {
      dispatch(setToken({ encoded_token: res.data.encoded_token }));
      navigate("/otp-verification");
      toast.success(res.message);
    }
  });

  return (
    <Container className="d-flex justify-content-center align-items-center mt-4 login-container">
      <div className="login-content">
        <img src={header_logo} alt="Home logo" className="home-logo" />
        <div className="my-3">
          <h3>Sign Up</h3>
          <p>Create your account.</p>
        </div>
        <div>
          <Button
            variant="transparent"
            className="text-light  w-100 gmail-login"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25"
              height="25"
              viewBox="0 0 48 48"
            >
              <path
                fill="#4caf50"
                d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"
              ></path>
              <path
                fill="#1e88e5"
                d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"
              ></path>
              <polygon
                fill="#e53935"
                points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"
              ></polygon>
              <path
                fill="#c62828"
                d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"
              ></path>
              <path
                fill="#fbc02d"
                d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"
              ></path>
            </svg>{" "}
            SIGN IN WITH GMAIL
          </Button>
          <div className="separator my-3">
            <span>OR</span>
          </div>
        </div>
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              doRegistration(values);
              //   navigate("/otp-verification");
              console.log("Form values:", values);
            }}
          >
            {({ errors, touched }) => (
              <FormikForm className="login-form" noValidate>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Field
                    as={Form.Control}
                    type="text"
                    name="name"
                    autofill="false"
                    placeholder="Enter your name"
                    autoComplete="off"
                    isInvalid={!!errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Field
                    as={Form.Control}
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    autoComplete="off"
                    isInvalid={!!errors.email && touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      className="login-password w-100"
                      autoComplete="off"
                      isInvalid={!!errors.password && touched.password}
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm your password"
                      className="login-password"
                      autoComplete="off"
                      isInvalid={
                        !!errors.confirmPassword && touched.confirmPassword
                      }
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibilityConfirm}
                        style={{ cursor: "pointer" }}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="text-center mt-4">
                  <span className="mb-1">
                    All your personal details are safe with us.
                  </span>

                  <p className="m-0">If you continue,you are accepting</p>
                  <p>
                    Collegely{" "}
                    <Link to={"/terms-conditions"} className="hover-txt">
                      Terms and Conditions
                    </Link>{" "}
                    and{" "}
                    <Link to={"/privacy-policy"} className="hover-txt">
                      Privacy Policy
                    </Link>
                  </p>
                </div>

                <Button
                  type="submit"
                  variant="primary"
                  className="w-100 my-3 register-btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Loading...
                    </>
                  ) : (
                    "Sign Up"
                  )}
                </Button>
                <div className="text-center mb-4">
                  <span>
                    Already have an account?{" "}
                    <Link to={"/login"} className="hover-txt">
                      Sign In
                    </Link>
                  </span>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};

export default Registration;
