import { createSlice } from "@reduxjs/toolkit";
import { setAuthToken } from "libs/HttpClient";

const initialState = {
  user: "",
  isUserLogin: false,
  accessToken: "",
  encoded_token: "",
  forgot_token: "",
};

export const authSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setToken: (state, { payload }) => {
      console.log("payload: ", payload);
      state.encoded_token = payload.encoded_token ? payload.encoded_token : "";
      state.forgot_token = payload.forgot_token ? payload.forgot_token : "";
    },

    loginSuccess: (state, { payload }) => {
      state.user = payload.userData;
      state.isUserLogin = true;
      state.accessToken = payload.accessToken;
      setAuthToken(payload.accessToken);
      return state;
    },
    updateUserData: (state, { payload }) => {
      state.user = payload.userData;
      return state;
    },
    logoutSuccess: () => initialState,
  },
});
export const { loginSuccess, logoutSuccess, updateUserData, setToken } =
  authSlice.actions;
export const loggedUser = (state) => state.user;
export default authSlice.reducer;
