import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "assets/scss/page/_auth.scss";
import header_logo from "assets/images/header_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useRegisterUser, useResetPassword } from "hooks";
import validationSchema from "./ResetPasswordValidation";
import { setToken } from "store/features/authSlice";

const ResetPasswordPage = () => {
  const forgot_token = useSelector((state) => state.user.forgot_token);
  const initialValues = {
    reset_token: forgot_token,
    password: "",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const { mutate: doResetPassword, isLoading } = useResetPassword((res) => {
    if (res.data) {
      dispatch(setToken({ forgot_token: "" }));
      toast.success(res.message);
      navigate("/login");
    }
  });

  return (
    <Container className="d-flex justify-content-center align-items-center mt-4 container">
      <div className="forgot-password-content">
        <img src={header_logo} alt="Home logo" className="home-logo" />
        <div className="my-3">
          <h3>Set New Password</h3>
          <p>Here you can set your new password.</p>
        </div>

        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              doResetPassword({
                reset_token: forgot_token,
                password: values.password,
              });
              //   navigate("/otp-verification");
            }}
          >
            {({ errors, touched }) => (
              <FormikForm className="login-form">
                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      className="login-password w-100"
                      autoComplete="off"
                      isInvalid={!!errors.password && touched.password}
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm your password"
                      className="login-password"
                      autoComplete="off"
                      isInvalid={
                        !!errors.confirmPassword && touched.confirmPassword
                      }
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibilityConfirm}
                        style={{ cursor: "pointer" }}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Button
                  type="submit"
                  variant="primary"
                  className="w-100 my-3 register-btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Loading...
                    </>
                  ) : (
                    "Confirm"
                  )}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};

export default ResetPasswordPage;
