import React from "react";
import { Container, Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import header_logo from "assets/images/header_logo.png";
import "assets/scss/page/_forgot_password.scss";
import { useForgotPassword } from "hooks"; // Assume this hook is created for handling forgot password requests
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import validationSchema from "./ForgotPasswordValidation";
import { useDispatch } from "react-redux";
import { setToken } from "store/features/authSlice";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { mutate: doForgotPassword } = useForgotPassword(
    (res) => {
      setIsSubmitting(false);
      if (res.data) {
        dispatch(setToken({ forgot_token: res.data.reset_token }));
        toast.success("Password reset email sent. Please check your inbox.");
        navigate("/otp-verification");
      } else {
        toast.error(res.message || "Failed to send password reset email.");
      }
    },
    (err) => {
      setIsSubmitting(false);
      toast.error("An error occurred. Please try again.");
    }
  );

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setIsSubmitting(true);
      doForgotPassword({ email: values.email });
    },
  });

  return (
    <Container className="d-flex justify-content-center align-items-center mt-5">
      <div className="forgot-password-content">
        <div className="text-start p-4 bg-dark text-white rounded">
          <img src={header_logo} alt="Home logo" className="home-logo" />
          <h2 className="mt-4">Forgot Password?</h2>
          <p>OTP will be sent to this email id.</p>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email Id</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.touched.email && formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              type="submit"
              disabled={isSubmitting}
              className="register-btn mt-3 fw-bold"
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Sending...
                </>
              ) : (
                "Send"
              )}
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPasswordPage;
