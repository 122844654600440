import React, { useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { Button, Form, Container, Modal } from "react-bootstrap";
import { NavItemsCommon } from "common/components";
import { EditEmailNavItems } from "config/navConfig";
import { toast } from "react-toastify";
import "assets/scss/page/_edit_profile.scss";
import { useDeactivateAccount } from "hooks";
import { ReactComponent as DeactivateIcon } from "assets/images/deactivate-icon.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutSuccess } from "store/features/authSlice";

// Validation schema for the form
const validationSchema = Yup.object({
  reason: Yup.string()
    .required("Please provide a reason for deactivation")
    .min(3, "Reason must be at least 10 characters long"),
});

const DeactivateAccount = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const { mutate: doDeactivateAccount, isLoading } = useDeactivateAccount(
    (res) => {
      if (res.data) {
        toast.success("Account deactivated successfully");

        dispatch(logoutSuccess());
        navigate("/login");

        // Handle navigation or other post-deactivation actions here
      }
    }
  );

  const handleSubmit = (values, { setSubmitting }) => {
    setFormData(values); // Save form data to trigger deactivation on confirmation
    setShowModal(true); // Show confirmation modal
    setSubmitting(false);
  };

  const handleDeactivate = () => {
    // Perform deactivation once user confirms
    if (formData) {
      doDeactivateAccount({ reason: formData.reason });
      setShowModal(false);
    }
  };

  return (
    <div>
      <h2>My Profile</h2>
      <NavItemsCommon navItemName={EditEmailNavItems} t={t} />
      <div className=" mt-5">
        <div className="d-flex justify-content-center">
          <DeactivateIcon />
        </div>
        <div className="d-flex justify-content-center">
          <Formik
            initialValues={{ reason: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <FormikForm className="mt-4 deactivate-form">
                <Form.Group className="mb-3">
                  <Form.Label>Reason for Deactivation</Form.Label>
                  <Field
                    as="textarea"
                    name="reason"
                    className={`form-control bg-dark text-light ${
                      touched.reason && errors.reason ? "is-invalid" : ""
                    }`}
                    placeholder="Please provide a reason for deactivation"
                    rows={4}
                  />
                  {touched.reason && errors.reason && (
                    <div className="invalid-feedback">{errors.reason}</div>
                  )}
                </Form.Group>

                <Button
                  type="submit"
                  className="w-100"
                  disabled={isSubmitting || isLoading}
                >
                  {isLoading ? "Submitting..." : "Deactivate"}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>

      {/* Modal for Confirmation */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="deactivate-modal"
      >
        <Modal.Header
          closeButton
          className="d-flex flex-column-reverse border-0"
        >
          <Modal.Title className="mt-4 w-100">
            <div className="d-flex justify-content-center">
              <DeactivateIcon />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-2 border-0 text-center">
          <div>Are you sure you want to deactivate your account?</div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="dark" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeactivate}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeactivateAccount;
