import React, { useState } from "react";
import { Accordion, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import "assets/scss/section/_private_sidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutSuccess } from "store/features/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import user_icon from "assets/images/account_circle.svg";
import path from "assets/images/Path.svg";
import add_card from "assets/images/add_card.svg";
import description from "assets/images/description.svg";
import favorite from "assets/images/favorite.svg";
import history from "assets/images/history.svg";
import move_item from "assets/images/move_item.svg";
import receipt_long from "assets/images/receipt_long.svg";
import reviews from "assets/images/reviews.svg";

const PrivateSidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate("/login");
  };
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <div
      className={`pvt-sidebar  vh-100 text-white p-3 ${
        isOpen ? "pvt-sidebar-open" : "pvt-sidebar-closed"
      }`}
    >
      <button
        className="pvt-sidebar-close-btn"
        onClick={toggleSidebar}
        aria-label="Close sidebar"
      >
        &times;
      </button>

      <Nav className="flex-column mt-4 sidebar-content">
        <Link
          to={"/edit-profile"}
          className="text-white text-decoration-none ms-3"
        >
          <img src={user_icon} alt="" /> My Profile
        </Link>
        <Accordion activeKey={activeKey} onSelect={handleToggle}>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              className={activeKey === "0" ? "custom-acc-header" : ""}
            >
              {/* Arrow indicator */}
              {activeKey === "0" ? <FaChevronUp /> : <FaChevronDown />}
              <img
                src={path}
                alt=""
                style={{ marginLeft: "5px", marginRight: "5px" }}
              />
              Ads Management
            </Accordion.Header>
            <Accordion.Body>
              <Nav.Link href="#create-ads" className="text-white">
                Create Ads
              </Nav.Link>
              <Nav.Link href="#ads-list" className="text-white">
                Ads List
              </Nav.Link>
              <Nav.Link href="#completed-ads" className="text-white">
                Completed Ads
              </Nav.Link>
              <Nav.Link href="#cancelled-ads" className="text-white">
                Cancelled Ads
              </Nav.Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Nav.Link href="#favourite" className="text-white">
          <img src={favorite} alt="" /> Favourite
        </Nav.Link>
        <Nav.Link href="#manage-card" className="text-white">
          <img src={add_card} alt="" /> Manage Card
        </Nav.Link>
        <Nav.Link href="#addon-history" className="text-white">
          <img src={history} alt="" /> Ad-on History
        </Nav.Link>
        <Nav.Link href="#payment-history" className="text-white">
          <img src={receipt_long} alt="" /> Payment History
        </Nav.Link>
        <Nav.Link href="#reports" className="text-white">
          <img src={description} alt="" /> Reports
        </Nav.Link>
        <Nav.Link href="#reviews" className="text-white">
          <img src={reviews} alt="" /> Reviews
        </Nav.Link>
        <Nav.Link onClick={handleLogout} className="text-white">
          <img src={move_item} alt="" /> Logout
        </Nav.Link>
      </Nav>
    </div>
  );
};

PrivateSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired, // Add toggleSidebar function prop
};

export default PrivateSidebar;
