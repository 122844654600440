import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PrivateHeader from "../Header/PrivateHeader";
import PrivateSidebar from "../Sidebar/PrivateSidebar";

const PrivateLayout = (props) => {
  const { t } = useTranslation();
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <>
      <div className="main-content-private">
        <div className="d-flex flex-column flex-grow-1 bg-dark private-container">
          {/* Pass toggleSidebar function to PrivateHeader */}
          <PrivateHeader toggleSidebar={toggleSidebar} />
          <div className={`flex-grow-1 text-light centered-content`}>
            {props.children}
          </div>
        </div>

        <PrivateSidebar
          isOpen={isSidebarVisible}
          toggleSidebar={toggleSidebar}
          t={t}
        />
        {/* Conditionally apply the 'open' class when sidebar is visible */}
      </div>
    </>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.string,
};

export { PrivateLayout };
