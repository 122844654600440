import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom"; // If you use react-router
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Sidebar } from "../Sidebar/Sidebar";
import { RightSidebar } from "../RightSidebar/RightSidebar";
import "assets/scss/_custom.scss";

const PublicLayout = ({ getSettingData, children, t }) => {
  const [currentView, setCurrentView] = useState("desktop");
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation(); // Hook to get current location

  // Extract the current page path from location
  const currentPage = location.pathname;

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 992px)"); // Adjust the max-width as needed
    const handleMediaChange = (e) => {
      setIsMobile(e.matches);
      if (e.matches) {
        setCurrentView("phone");
      } else {
        setCurrentView("desktop");
      }
    };
    // Initial check
    handleMediaChange(mediaQuery);
    // Add listener
    mediaQuery.addListener(handleMediaChange);
    // Cleanup listener on unmount
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  const [sidebars, setSidebars] = useState({
    left: false,
    right: false,
  });

  const handleToggleSidebar = (leftOpen, rightOpen) => {
    setSidebars({
      left: leftOpen,
      right: rightOpen,
    });
  };

  // Define the patterns
  const hideHeaderAndSidebarsPatterns = [
    "/about",
    "/contact",
    "/faq",
    "/page/*",
  ];

  const hideAllComponentsPatterns = [
    "/login",
    "/register",
    "/otp-verification",
    "/forgot-password",
    "/reset-password",
  ];

  // Determine if currentPage matches any of the patterns
  const isMatchingPath = (pattern, path) => {
    const regex = new RegExp(`^${pattern.replace("*", ".*")}$`);
    return regex.test(path);
  };

  const hideHeaderAndSidebars = hideHeaderAndSidebarsPatterns.some((pattern) =>
    isMatchingPath(pattern, currentPage)
  );

  const hideAllComponents = hideAllComponentsPatterns.some((pattern) =>
    isMatchingPath(pattern, currentPage)
  );

  return (
    <>
      <div className="main-content">
        {/* Conditionally render Sidebar components */}
        {!hideHeaderAndSidebars &&
          !hideAllComponents &&
          currentView === "desktop" && (
            <Sidebar getSettingData={getSettingData} t={t} />
          )}

        {/* Main Content Area */}
        {/* Conditionally render Header component */}
        <div className="d-flex flex-column flex-grow-1 bg-dark">
          {!hideAllComponents && (
            <Header
              hideHeaderAndSidebars={hideHeaderAndSidebars}
              getSettingData={getSettingData}
              t={t}
              onToggleSidebar={handleToggleSidebar}
            />
          )}

          {/* Main Content */}
          <div
            className={`flex-grow-1 text-light ${
              !hideHeaderAndSidebars
                ? hideAllComponents
                  ? "custom-container"
                  : "main-container"
                : currentView === "desktop"
                ? "mt-0"
                : "mt-5"
            }`}
          >
            {children}
          </div>

          {/* Footer Component */}
        </div>

        {/* Conditionally render Right Sidebar component */}
        {!hideHeaderAndSidebars &&
          !hideAllComponents &&
          currentView === "desktop" && (
            <RightSidebar getSettingData={getSettingData} t={t} />
          )}
      </div>

      <Footer getSettingData={getSettingData} t={t} />
    </>
  );
};

PublicLayout.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  t: PropTypes.func,
};

export { PublicLayout };
