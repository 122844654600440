import React from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { BiBell, BiChat } from "react-icons/bi";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import default_img from "assets/images/team2.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { imagePreviewFormik } from "helpers";

const PrivateHeader = ({ toggleSidebar }) => {
  const user = useSelector((state) => state.user);
  return (
    <Navbar
      bg="dark"
      variant="dark"
      className="justify-content-between pvt-header ps-3"
    >
      <Navbar.Brand className="private-header">
        <Link to={"/dashboard"} className="text-light text-decoration-none">
          Collegely
        </Link>
      </Navbar.Brand>
      <Nav className="d-flex align-items-center gap-2 me-3">
        <Nav.Link href="#chat">
          <BiChat size={28} color="#fff" />
        </Nav.Link>
        <Nav.Link href="#notifications">
          <BiBell size={28} color="#fff" />
        </Nav.Link>
        <Link to={"/edit-profile"}>
          <Image
            src={imagePreviewFormik(user.user.profile_image)}
            roundedCircle
            width="40"
            height="40"
          />
        </Link>
        <Nav.Link onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faGear} className="text-light fs-4" />
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

// Define PropTypes to ensure toggleSidebar is passed
PrivateHeader.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default PrivateHeader;
