import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "assets/scss/page/_main.scss";

const Main = ({ getSettingData, t }) => {
  const categoriesData = {
    categories: {
      Jobs: [
        "accounting + finance",
        "admin / office",
        "arch / engineering",
        "art / media / design",
        "biotech / science",
        "business / mgmt",
        "customer service",
        "education",
        "etc / misc",
        "general labor",
        "government",
        "human resources",
        "legal / paralegal",
        "manufacturing",
        "marketing / pr / ad",
        "medical / health",
        "nonprofit sector",
        "real estate",
        "retail / wholesale",
        "sales / biz dev",
        "salon / spa / fitness",
        "security",
        "skilled trade / craft",
        "software / qa / dba",
        "systems / network",
        "technical support",
        "transport",
        "tv / film / video",
        "web / info design",
        "writing / editing",
      ],
      Housing: [
        "apts / housing",
        "housing swap",
        "housing wanted",
        "office / commercial",
        "parking / storage",
        "real estate for sale",
        "rooms / shared",
        "rooms wanted",
        "sublets / temporary",
        "vacation rentals",
      ],
      collegePrograms: [
        "Agriculture, Food",
        "Natural Resources",
        "Arts & Sciences",
        "Business",
        "Engineering",
        "Health & Human",
        "Law & Policy",
      ],
      Gigs: [
        "creative",
        "talent",
        "computer",
        "event",
        "labor",
        "crew",
        "writing",
      ],
      Services: [
        "automotive",
        "beauty",
        "cell/mobile",
        "creative",
        "computer",
        "financial",
        "health/well",
        "household",
        "legal",
        "lessons",
        "marine",
        "pet",
        "real estate",
        "skilled trade",
        "sm biz ads",
        "travel/vac",
        "write/ed/tran",
      ],
      forSale: [
        "antiques",
        "appliances",
        "arts+crafts",
        "atv/utv/sno",
        "auto parts",
        "aviation",
        "baby+kid",
      ],
    },
  };

  // Function to render category lists
  const renderCategoryList = (items) => {
    return (
      <ul className="list-unstyled category-list">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  // Function to render "Jobs" category with two columns if more than 25 items
  const renderJobsCategory = () => {
    const jobs = categoriesData.categories.Jobs;
    if (jobs.length > 25) {
      const firstHalf = jobs.slice(0, Math.ceil(jobs.length / 2));
      const secondHalf = jobs.slice(Math.ceil(jobs.length / 2));
      return (
        <Row>
          <Col xs={12} sm={6}>
            {renderCategoryList(firstHalf)}
          </Col>
          <Col xs={12} sm={6}>
            {renderCategoryList(secondHalf)}
          </Col>
        </Row>
      );
    }
    return renderCategoryList(jobs);
  };

  const renderServiceCategory = () => {
    const service = categoriesData.categories.Services;
    if (service.length > 25) {
      const firstHalf = service.slice(0, Math.ceil(service.length / 2));
      const secondHalf = service.slice(Math.ceil(service.length / 2));
      return (
        <Row>
          <Col xs={12} sm={6}>
            {renderCategoryList(firstHalf)}
          </Col>
          <Col xs={12} sm={6}>
            {renderCategoryList(secondHalf)}
          </Col>
        </Row>
      );
    }
    return renderCategoryList(service);
  };

  return (
    <Container fluid className="content-area home-container">
      <Row>
        {/* Jobs Section */}
        <Col xs={12} md={6} lg={4}>
          <Card className="mb-4 bg-dark text-white p-0">
            <div className="custom-heading">Jobs</div>
            <Card.Body>{renderJobsCategory()}</Card.Body>
          </Card>

          {/* Services Section */}
          <Card className="mb-4 bg-dark text-white p-0">
            <div className="custom-heading">Services</div>
            <Card.Body>{renderServiceCategory()}</Card.Body>
          </Card>
        </Col>

        {/* Housing and Gigs Sections */}
        <Col xs={12} md={6} lg={4}>
          <Card className="mb-4 bg-dark text-white p-0">
            <div className="custom-heading">Housing</div>
            <Card.Body>
              {renderCategoryList(categoriesData.categories.Housing)}
            </Card.Body>
          </Card>

          <Card className="mb-4 bg-dark text-white p-0">
            <div className="custom-heading">Gigs</div>
            <Card.Body>
              {renderCategoryList(categoriesData.categories.Gigs)}
            </Card.Body>
          </Card>

          <Card className="mb-4 bg-dark text-white p-0">
            <div className="custom-heading">For Sale</div>
            <Card.Body>
              {renderCategoryList(categoriesData.categories.forSale)}
            </Card.Body>
          </Card>
        </Col>

        {/* College Programs Section */}
        <Col xs={12} lg={4}>
          <Card className="mb-4 bg-dark text-white p-0">
            <div className="custom-heading">College Programs</div>
            <Card.Body>
              {renderCategoryList(categoriesData.categories.collegePrograms)}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
