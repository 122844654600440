import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";

const CMSViewPage = React.lazy(() => import("./CMSViewPage"));
const CmsRoutes = ({ t }) => {
  return (
    <Routes>
      <Route exact path={`/:slug`} element={<CMSViewPage t={t} />} />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
CmsRoutes.propTypes = {
  t: PropTypes.func,
};
export default CmsRoutes;
