export const EditEmailNavItems = [
  {
    path: "/edit-profile",
    translationKey: "Edit Profile",
  },
  {
    path: "/change-password",
    translationKey: "Change Password",
  },
  {
    path: "/deactivate-account",
    translationKey: "Deactivate Account",
  },
];
