import { AuthService } from "api";
import { toast } from "react-toastify";

const { useQuery, useMutation } = require("react-query");

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useRegisterUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.registerUser, {
    onSuccess,
    onError,
  });
};

const useOtpVerification = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.verifyOtp, {
    onSuccess,
    onError,
  });
};

const useResendOtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resendOtp, {
    onSuccess,
    onError,
  });
};
const useLoginUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.loginUser, {
    onSuccess,
    onError,
  });
};
const useForgotPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.forgotPassword, {
    onSuccess,
    onError,
  });
};
const useResetPassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(AuthService.resetPassword, {
    onSuccess,
    onError,
  });
};
export {
  useRegisterUser,
  useOtpVerification,
  useResendOtp,
  useLoginUser,
  useForgotPassword,
  useResetPassword,
};
