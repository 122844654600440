import { UserService } from "api";
import { toast } from "react-toastify";

const { useQuery, useMutation } = require("react-query");

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetProfile = (onSuccess, onError = onDefaultError) => {
  return useQuery("get-profile", UserService.getProfile, {
    onSuccess,
    onError,
  });
};

const useUpdateProfile = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateProfile, {
    onSuccess,
    onError,
  });
};
const useUpdateEmail = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateEmail, {
    onSuccess,
    onError,
  });
};
const useUpdateEmailVerify = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.updateEmailVerify, {
    onSuccess,
    onError,
  });
};

const useResendEmailOtp = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.resendOtp, {
    onSuccess,
    onError,
  });
};
const useSendOtpForMobile = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.sendOtpForMobile, {
    onSuccess,
    onError,
  });
};
const useChangePassword = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.changePassword, {
    onSuccess,
    onError,
  });
};
const useDeactivateAccount = (onSuccess, onError = onDefaultError) => {
  return useMutation(UserService.deactivateAccount, {
    onSuccess,
    onError,
  });
};

export {
  useGetProfile,
  useUpdateProfile,
  useUpdateEmail,
  useUpdateEmailVerify,
  useResendEmailOtp,
  useSendOtpForMobile,
  useChangePassword,
  useDeactivateAccount,
};
