import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import validationSchema from "./LoginValidation";
import "assets/scss/page/_auth.scss";
import header_logo from "assets/images/header_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLoginUser, useRegisterUser } from "hooks";
import { loginSuccess, setToken } from "store/features/authSlice";
import { setAuthToken } from "libs";

const initialValues = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { mutate: doLogin, isLoading } = useLoginUser((res) => {
    if (res.data.encoded_token) {
      dispatch(setToken({ encoded_token: res.data.encoded_token }));
      navigate("/otp-verification");
      toast.error(
        "Email not verified please check your email inbox and verify your email"
      );
    } else {
      console.log("res: ", res.data);
      const dataStore = {
        userData: res.data.user_data,
        isLoggedIn: true,
        accessToken: res.data.access_token,
      };
      console.log("dataStore: ", dataStore);
      dispatch(loginSuccess(dataStore));
      setTimeout(function () {
        setAuthToken(res.data.access_token);
        navigate("/dashboard");
      }, 1000);
      toast.success(res.message);
    }
  });

  return (
    <Container className="d-flex justify-content-center align-items-center mt-4 login-container">
      <div className="login-content w-100">
        <img src={header_logo} alt="Home logo" className="home-logo" />
        <div className="my-3">
          <h3>Sign in</h3>
          <p>Enter your email and password to login to your account.</p>
        </div>
        <div>
          <Button
            variant="transparent"
            className="text-light  w-100 gmail-login"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25"
              height="25"
              viewBox="0 0 48 48"
            >
              <path
                fill="#4caf50"
                d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"
              ></path>
              <path
                fill="#1e88e5"
                d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"
              ></path>
              <polygon
                fill="#e53935"
                points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"
              ></polygon>
              <path
                fill="#c62828"
                d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"
              ></path>
              <path
                fill="#fbc02d"
                d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"
              ></path>
            </svg>{" "}
            SIGN IN WITH GMAIL
          </Button>
          <div className="separator my-3">
            <span>OR</span>
          </div>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              doLogin(values);
            }}
          >
            {({ errors, touched }) => (
              <FormikForm className="login-form" noValidate>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Field
                    as={Form.Control}
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    autoComplete="off"
                    isInvalid={!!errors.email && touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      className="login-password w-100"
                      autoComplete="off"
                      isInvalid={!!errors.password && touched.password}
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="text-end">
                  <Link to={"/forgot-password"} className="hover-txt">
                    Forgot Password?
                  </Link>
                </div>

                <Button
                  type="submit"
                  variant="primary"
                  className="w-100 my-3 register-btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Loading...
                    </>
                  ) : (
                    "Sign In"
                  )}
                </Button>
                <div className="text-center mb-4">
                  <span>
                    Don't have an account?{"  "}
                    <Link to={"/register"} className="hover-txt">
                      Create Now
                    </Link>
                  </span>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};

export default LoginPage;
