import * as Yup from "yup";
import "helpers/customValidation"; // Assuming custom validation methods are defined here

// Schema for profile validation
const validationSchema = Yup.object({
  full_name: Yup.string()
    .required("Please enter your full name.") // Custom message for required field
    .matches(
      /^[a-zA-Z\s]+$/,
      "Full name should only contain alphabets and spaces." // Custom message for pattern mismatch
    ),
  email: Yup.string()
    .email("Please enter a valid email address.") // Custom message for invalid email
    .required("Email is required."), // Custom message for required field
});

// Schema for email change validation
const validationSchemaEmail = Yup.object({
  newEmail: Yup.string()
    .email("Please enter a valid email address.") // Custom message for invalid email
    .required("Email is required."), // Custom message for required field
});

// Schema for OTP verification validation
const validationSchemaVerify = Yup.object({
  verification_otp: Yup.string()
    .required("OTP is required.") // Custom message for required field
    .matches(/^[0-9\s]+$/, "OTP should only contain numbers."), // Custom message for pattern mismatch
});

// Schema for phone number validation
const validationSchemaPhone = Yup.object({
  newMobile: Yup.string()
    .phoneCheck("Please enter a valid phone number.") // Custom message for phone number validation
    .required("Phone number is required."), // Custom message for required field
});

export {
  validationSchema,
  validationSchemaEmail,
  validationSchemaVerify,
  validationSchemaPhone,
};
