import { useGetCmsData } from "hooks";
import React from "react";
import { Container } from "react-bootstrap";
import "assets/scss/page/_cms.scss";
import { useNavigate } from "react-router-dom";
const Privacy = ({ getSettingData, t }) => {
  const navigate = useNavigate();
  const {
    data: getCmsData,
    error,
    isLoading,
  } = useGetCmsData(
    "privacy-policy",
    () => {},
    (error) => {
      console.log("In this api error occurs " + error.message);
    }
  );
  const cmsData = getCmsData?.data;
  // Handle loading state and error
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !cmsData) {
    navigate("/404", {
      state: {
        message:
          "The page you are looking for might be removed or is temporarily unavailable",
      },
    });
    return null; // Return null to prevent rendering anything else
  }

  return (
    <Container
      fluid
      className="cms-container bg-dark text-white common-spacing"
    >
      <div className="mb-4">
        <h2 className="section-title d-flex flex-column">COLLEGELY</h2>
        <span className="cms-description section-title">
          {cmsData?.title.toUpperCase() || "PRIVACY POLICY"}
        </span>
      </div>
      <div
        className="mt-2"
        dangerouslySetInnerHTML={{ __html: cmsData?.description }}
      ></div>
    </Container>
  );
};

export default Privacy;
