import client from "../libs/HttpClient";
class AuthService {
  static registerUser(request) {
    return client.post("auth/register", request);
  }
  static verifyOtp(request) {
    return client.post("auth/verify-otp", request);
  }
  static resendOtp(request) {
    return client.post("auth/resend-otp", request);
  }
  static loginUser(request) {
    return client.post("auth/login", request);
  }
  static forgotPassword(request) {
    return client.put("auth/forgot-password", request);
  }
  static resetPassword(request) {
    return client.put("auth/reset-password", request);
  }
}
export { AuthService };
