import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { PrivateLayout } from "common/layouts";
import { loader } from "store/features/loaderSlice";
import { loggedUser } from "store/features/authSlice";

const PrivateRoute = () => {
  const checkLoading = useSelector(loader);
  const checkLoggedInUser = useSelector(loggedUser);
  console.log("checkLoggedInUser: ", checkLoggedInUser);
  const location = useLocation();
  useEffect(() => {
    if (window.localStorage.isShowingError) {
      toast.error(window.localStorage.isShowingError);
      window.localStorage.removeItem("isShowingError");
    }
  });

  const isUserLogin = checkLoggedInUser.isUserLogin;
  return isUserLogin ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
