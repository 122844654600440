import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile_number: Yup.string()
    .length(10, "Contact Number must be in 10 digits")
    .matches(/^[0-9]+$/, "Contact Number must be digits only")
    .required("Contact Number is required"),
  country_code_id: Yup.string().required("Contact_id is required"),
  email: Yup.string("Email is required")
    .email("Invalid email address")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email is invalid")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});
export default validationSchema;
