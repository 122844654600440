import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import "assets/scss/page/_edit_profile.scss";

const NavItemsCommon = ({ navItemName, t }) => {
  const location = useLocation();

  return (
    <Nav className="tab-navigation">
      {navItemName.map(({ path, translationKey }) => (
        <Nav.Item key={path}>
          <NavLink
            to={path}
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            {t(translationKey)}
          </NavLink>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export { NavItemsCommon };
